import React from "react";

const FacebookIcon = (props) => {

    return (


        <svg width="1em" height="1em" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="32" cy="32" r="32" fill={props.colorBG} />
            <g clipPath="url(#Facebook)">
                <path fillRule="evenodd" clipRule="evenodd" d="M34.2503 23.5H38V19H34.2503C31.3558 19 29.0008 21.355 29.0008 24.2495V26.4995H26.0005V31H28.9999V43H33.5004V31H37.2502L38 26.4995H33.5004V24.2495C33.5004 23.8429 33.8437 23.4996 34.2503 23.4996V23.5001L34.2503 23.5Z" fill={props.colorIcon} />
            </g>
            <defs>
                <clipPath id="Facebook">
                    <rect width="24" height="24" fill="white" transform="translate(20 19)" />
                </clipPath>
            </defs>
        </svg>



    );
}

export default FacebookIcon;