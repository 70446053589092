import React from "react";

import "./PersonalityGrid.scss"

import { Personality } from "./PersonialityItems.js";



const PersonalityGrid = () => {

    return (
        <div className="personality-grid-container">        {
            Personality.map((personality, index) => {
                return (
                    <div key={index} className="animation-wrap">

                        <div>{personality.component}</div>

                        <p>{personality.title}</p>
                    </div>
                )
            })
        }
        </div>

    )

}


export default PersonalityGrid;