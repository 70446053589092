import React from "react";

const LinkedInIcon = (props) => {

    return (


        <svg width="1em" height="1em" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="32" cy="32" r="32" fill={props.colorBG} />
            <g clipPath="url(#LinkedIn)">
                <path fillRule="evenodd" clipRule="evenodd" d="M29.572 25.7142H34.3143V28.1454H34.3829C35.0434 26.9606 36.6583 25.7142 39.0664 25.7142C44.074 25.7142 45.0002 28.8306 45.0002 32.8846V41.1428H40.0545V33.8223C40.0545 32.0772 40.0182 29.8307 37.4829 29.8307C34.9078 29.8307 34.5131 31.7304 34.5131 33.6952V41.1428H29.572V25.7142H29.572ZM26.1434 21.4285C26.1434 22.8481 24.9913 24.0001 23.5718 24.0001C22.1522 24.0001 20.9998 22.8481 20.9998 21.4285C20.9998 20.009 22.1522 18.8569 23.5718 18.8569C24.9913 18.8569 26.1434 20.009 26.1434 21.4285ZM20.9998 25.7142H26.1434V41.1428H20.9998V25.7142V25.7142Z" fill={props.colorIcon} />
            </g>
            <defs>
                <clipPath id="LinkedIn">
                    <rect width="24" height="24" fill="white" transform="translate(21 18)" />
                </clipPath>
            </defs>
        </svg>



    );
}

export default LinkedInIcon;