import React from "react";

const PurposeIcon = (props) => {

    return (

        <svg width="1em" height="1em" viewBox="0 0 192 192" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="96" cy="96" r="96" fill={props.colorBG} />
            <path d="M49.9998 142L142 49.9999" stroke={props.colorIcon} strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" className="icon-stroke" />
            <path d="M49.9998 49.9999L142 142" stroke={props.colorIcon} strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" className="icon-stroke" />
        </svg>



    );
}

export default PurposeIcon;