import React from "react";

import "./ChecklistPageGrid.scss"

import NextPage from "../../NextPage/NextPage.js";

import ChecklistGrid from "../../Checklist/ChecklistGrid.js"

import HeaderChecklist from "../../Header/HeaderChecklist/HeaderChecklist.js"

const ChecklistPageGrid = () => {


    return (
        <div className="checklist-grid-container">
            <HeaderChecklist />
            <ChecklistGrid />

            <NextPage
                leftPath="/purpose"
                leftName="purpose"
                rightPath="/"
                rightName="info"
                show={true}
            />

        </div>
    )

}

export default ChecklistPageGrid