import Property01 from "../../../img/lotties/components/basics/properties/Basics01Opacity";
import Property02 from "../../../img/lotties/components/basics/properties/Basics02Scale";
import Property03 from "../../../img/lotties/components/basics/properties/Basics03Color";
import Property04 from "../../../img/lotties/components/basics/properties/Basics04Position";
import Property05 from "../../../img/lotties/components/basics/properties/Basics05Rotation";
import Property06 from "../../../img/lotties/components/basics/properties/Basics06Blur";
import Property07 from "../../../img/lotties/components/basics/properties/Basics07Depth";



export const Properties = [
    {
        title: 'Opacity',
        component: <Property01 />
    },
    {
        title: 'Scale',
        component: <Property02 />
    },
    {
        title: 'Color',
        component: <Property03 />
    },
    {
        title: 'Position',
        component: <Property04 />
    },
    {
        title: 'Rotation',
        component: <Property05 />
    },
    {
        title: 'Blur',
        component: <Property06 />
    },
    {
        title: 'Depth',
        component: <Property07 />
    },


]