import React from "react";
import ScrollAnimation from "react-animate-on-scroll";


import PersonalityGrid from "../../../../animGrids/personality/PersonalityGrid.js"

import "../textChapters.scss"




const Personality = () => {


    return (



        <section className="text-chapter personality-container">
            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <div className="chapter-header">
                    <h2>personality</h2>
                    <p>Animation is incredibly useful for attracting attention. Combining animation with the most important elements of an interface ensures that users will definitely notice the elements.</p>
                </div>
            </ScrollAnimation>

            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <section>
                    <h3>BRANDING</h3>
                    <p>To make a product successful it is importan that it appeals to its target audience. It is crucial to communicate the brands personality through design.</p>
                    <p>Every animation in an UI creates a certain personality. However, the design decisions should not be made on the basis of each individual UI element, but on the basis of the whole brand personality.</p>
                </section>
            </ScrollAnimation>

            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <section>
                    <h3>CONVEY PERSONALITY</h3>
                    <p>The easing of an UI element can say a lot about the physical characteristics and the personality of the brand. Looking at real life motion can also help to find the right movement for your interface.</p>

                    <PersonalityGrid />
                </section>
            </ScrollAnimation>
        </section>

    )

}

export default Personality