import React from "react";
import "./BasicsGrid.scss"
import ScrollAnimation from "react-animate-on-scroll";


import PrinciplesGrid from "../../animGrids/principles/PrinciplesGrid.js"
import TypesGrid from "../../animGrids/types/TypesGrid.js"
import BasicsGrid from "../../animGrids/properties/PropertiesGrid.js"
import EasingGrid from "../../animGrids/easing/EasingGrid.js"


import NextPage from "../../NextPage/NextPage.js";

import HeaderBasics from "../../Header/HeaderBasics/HeaderBasics.js"

import Duration from "../../../img/icons/components/Duration.js";

import color from "../../../style/basic/_config.module.scss"


const BasicsPageGrid = () => {




    return (
        <div className="basicsPage-grid-container">

            <HeaderBasics />
            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <section className="basic-properties">
                    <h2>basic properties</h2>
                    <p>Animation is change over time.
                        The different properties of an UI element are describing what exactly is changing over time.</p>

                    <p className="mb"> The main properties of any UI element that can be changed are:</p>
                    <BasicsGrid />
                </section>
            </ScrollAnimation>

            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <section className="duration">
                    <h2>duration</h2>
                    <p>Without time, there is no animation. The duration of an animation tells us how long the animation will last. But how long should an UI animation take?</p>

                    <p> Every time users are interacting with the interface, they try to get something done. So the UI animation shouldn’t take too long. The sweet spot of the duration of UI animations ranges between 200ms - 500ms.
                        <br />
                        Try to keep them between 100ms - 750ms. The duration depends on how big the UI element is and how far it is going to move. Small UI elements are closer to the 200ms - 350ms range. Big UI elements that cover a lot of area will need more time to move from one spot to another.</p>

                    <Duration className="durationIcon" width={375} height={105} color1={color.blueTwo} color2={color.blueThree} color3={color.white} color4={color.blueFour} />

                </section>
            </ScrollAnimation>

            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <section className="easing">
                    <h2>easing</h2>
                    <p className="mb">Easing describes the rate at which an UI element changes over time. It can also be seen in the sense of acceleration and deceleration of UI elements. Some easings suggest personality or aim for specific movement like entrances or exits.</p>
                    <EasingGrid />
                </section>
            </ScrollAnimation>

            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <section className="anim-types">
                    <h2>animation types</h2>
                    <p>When using UI animations, certain pattern of different animation types come up. These patterns are helpful to define the purpose of the animation. It also helps you to keep your design decisions consistent.</p>
                    <p className="mb">
                        This are the possible purposes of animation in your interface:</p>
                    <TypesGrid />
                </section>
            </ScrollAnimation>

            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <section className="principles">
                    <h2>traditional animation principles</h2>
                    <p>The traditional principles of animation are the foundation of classic animation and motion graphics in general. With the book “The Illusion of Life” Disney has set the foundation of animation and influences the animation industry till today.</p>
                    <p className="mb">  The 12 classic principles are essential for creating animations, if you want to communicate a natural and lifelike feel. You can use them not only for cartoon characters but also for UI animations.</p>
                    <PrinciplesGrid />
                </section>
            </ScrollAnimation>


            <NextPage

                leftPath="/"
                leftName="info"
                rightPath="/design"
                rightName="design"
                show={true}
            />


        </div >

    )

}

export default BasicsPageGrid