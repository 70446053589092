import React, { createRef, useEffect } from 'react';
import lottie from 'lottie-web';
import animation from '../../../json/basics/properties/06_Blur.json';


const BasicsBlur = () => {

    let animationContainer = createRef();

    useEffect(() => {
        lottie.loadAnimation({
            container: animationContainer.current, // current instance of our container!
            animationData: animation, // animation file!
            renderer: "canvas",
            loop: true,
            autoplay: true
        });
    }, [])

    //style={{ height: 500 }}

    return (
        <div className="animation-container blur" ref={animationContainer}

        />

    )

}

export default BasicsBlur