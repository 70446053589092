import React from "react";


//ICONS
import InstagramIcon from "../../../../../img/icons/components/Social/InstagramIcon.js";
import MailIcon from "../../../../../img/icons/components/Social/MailIcon.js";
import LinkedInIcon from "../../../../../img/icons/components/Social/LinkedInIcon.js";



import color from "../../../../../style/basic/_config.module.scss";
import "./Contact.scss"

const Contact = () => {



    return (
        <div className="contact-container">

            <h3>Contact</h3>

            <p>Do you have some questions or feedback you would like to share? Feel free to drop a message and have a chat with me.</p>

            <section className="contact-icons">

                <a href="https://www.instagram.com/loopmation/" target="_blank">
                    <InstagramIcon className="icon hover-icon" colorBG={color.blueThree} colorIcon={color.white} width={64} height={64} />
                </a>

                <a href="mailto:hello@stefanlamprecht.com?subject=UI-Animation.net">
                    <MailIcon className="icon hover-icon" colorBG={color.blueThree} colorIcon={color.white} width={64} height={64}
                    />
                </a>

                <a href="https://at.linkedin.com/in/stefan-lamprecht-467b56175" target="_blank">
                    <LinkedInIcon className="icon hover-icon" colorBG={color.blueThree} colorIcon={color.white} width={64} height={64}
                    />
                </a>
            </section>




        </div>

    );
}

export default Contact;