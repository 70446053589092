import React from "react";

import "./HomeGrid.scss"


// CARDS
import CardBasics from "../../Cards/Basics/CardBasics.js"
import CardDesign from "../../Cards/Design/CardDesign.js"
import CardPurpose from "../../Cards/Purpose/CardPurpose.js"
import CardChecklist from "../../Cards/Checklist/CardChecklist.js"

//Share
import Share from "./textContent/Share/Share.js"
import Contact from "./textContent/Contact/Contact.js"
// import Reference from "../../Reference/Reference.js"
import Reference from "./textContent/Reference/Reference.js"


//Logo
import Logo from "../../../img/icons/components/Logo"
import LogoGrow from "../../../img/lotties/components/icon/Logo/Logo_anim_grow"

//color
import color from "../../../style/basic/_config.module.scss"





const HomeGrid = () => {


    return (
        <div className="home-grid-container">

            <div className="header-bg-wrap">
                <LogoGrow className="header-bg" />
            </div>


            <h1 className="home-intro-text">ui-animaiton.net is a resource for designers to consider when they strive to create meaningful and usable UI animations.
            </h1>



            <section className="menu-cards">

                <CardBasics />
                <CardDesign />
                <CardPurpose />
                <CardChecklist />

            </section>

            <p className="home-text">ui-animaiton.net is focused on supporting designers to create meaningful and useable UI animations through a broad overview of the topic and the corresponding aspects of animation and user experience that come along with it.</p>

            <Logo className="logo-trenner" color={color.blueTwo} width={256} height={128} />


            <p className="home-text">Animation is no longer a secondary design element in todays interaction design. It has become an essential part to support human-centred products and experiences. This website helps designer to understand and apply the vast field of animation through clear examples and useful resources.
            </p>


            <Share />
            <Contact />
            <Reference />

        </div>
    )

}

export default HomeGrid