import React from "react";

import "./EasingGrid.scss"

import { Easings } from "./EasingItems";



const EasingsGrid = () => {

    return (
        <div className="easing-grid-container">        {
            Easings.map((easing, index) => {
                return (
                    <div key={index} className="animation-wrap">

                        <div>{easing.component}</div>

                        <p>{easing.title}</p>
                    </div>
                )
            })
        }
        </div>

    )

}


export default EasingsGrid;