import React from "react";

import "./Reference.scss"

const Reference = () => {




    return (
        <div className="reference-container">
            <h3>Reference & Resources</h3>



            <div>
                <section>
                    <ol>
                        <li><a className="hover-card-links" href="https://valhead.com/books/" target="_blank" > <div> Val Head </div> <br /> Designing Interface Animation</a></li>
                        <li><a className="hover-card-links" href="https://www.nngroup.com/articles/animation-duration/" target="_blank"> <div>Page Laubheimer</div> <br /> Executing UX Animations</a></li>
                        <li><a className="hover-card-links" href="https://medium.com/ux-in-motion/creating-usability-with-motion-the-ux-in-motion-manifesto-a87a4584ddc" target="_blank"> <div>Issara Willenskomer</div> <br /> Creating usability with motion</a></li>
                        <li><a className="hover-card-links" href="https://abookapart.com/products/animation-at-work" target="_blank"> <div>Rachel Nabors</div> <br /> Animation at Work</a></li>
                        <li><a className="hover-card-links" href="https://motionandmeaning.io" target="_blank"> <div>Val Head & Cennydd Bowles</div> <br /> Motion and Meaning Podcast</a></li>
                        <li><a className="hover-card-links" href="https://uianimationnewsletter.com" target="_blank"> <div>Val Head</div> <br /> UI Animation Newsletter</a></li>
                        <li><a className="hover-card-links" href="https://easings.net" target="_blank"> <div>Andrey Sitnik & Ivan Solovev</div> <br /> Easing.net</a></li>
                        <li><a className="hover-card-links" href=" https://cubic-bezier.com/" target="_blank"> <div>Lea Verou</div> <br /> Cubic-bezier.com</a></li>
                        <li><a className="hover-card-links" href="https://www.nngroup.com/articles/animation-usability/" target="_blank"> <div>Aurora Harley</div> <br /> Animation for Attention and Comprehension</a></li>
                        <li><a className="hover-card-links" href="https://www.nngroup.com/articles/animation-purpose-ux/" target="_blank"> <div>Page Laubheimer</div> <br /> The Role of Animation and Motion in UX</a></li>
                        <li><a className="hover-card-links" href=" https://lottiefiles.com" target="_blank"> <div>Airbnb</div> <br /> LottieFiles</a></li>
                    </ol>
                </section>
            </div>
        </div>
    );
}

export default Reference;