import React, { createRef, useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
import animation from '../../json/icon/Menu.json';


const MenuIcon = ({ menuOpen }) => {

    const [play, setPlay] = useState(false);
    const [direction, setDirection] = useState(1);
    const [isClicked, setIsClicked] = useState(menuOpen);


    const handleInteraction = (e) => {

        if (!isClicked) {
            setDirection(1)
            setPlay(true)
            setIsClicked(!isClicked)

        }

        if (isClicked) {
            setDirection(-1)
            setPlay(true)
            setIsClicked(!isClicked)
        }
    }

    return (

        <Lottie
            loop={false}
            speed={1}
            play={play}
            animationData={animation}
            direction={direction}
            onComplete={() => setPlay(false)}

            onClick={handleInteraction}
            className="animation-container"
        />


    )

}

export default MenuIcon