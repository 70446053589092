import React, { createRef, useEffect } from 'react';
import lottie from 'lottie-web';
import animation from '../../json/design/doku/02_doku_easing.json';


const DokuEasing = () => {

    let animationContainer = createRef();

    useEffect(() => {
        lottie.loadAnimation({
            container: animationContainer.current, // current instance of our container!
            animationData: animation, // animation file!
            renderer: "svg",
            loop: true,
            autoplay: true
        });
    }, [])



    return (
        <div className="animation-container" ref={animationContainer} />

    )

}

export default DokuEasing