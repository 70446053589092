import React from "react";

import "./PropertiesGrid.scss"

import { Properties } from "./PropertiesItems";



const PropertiesGrid = () => {

    return (
        <div className="properties-grid-container">        {
            Properties.map((property, index) => {
                return (
                    <div key={index} className="animation-wrap">

                        <div className="animation-container">{property.component}</div>

                        <p>{property.title}</p>
                    </div>
                )
            })
        }
        </div>

    )

}


export default PropertiesGrid;