import React from "react";

import "./CopyrightBar.scss"



//color
import color from "../../style/basic/_config.module.scss"







const CopyrightBar = () => {


    return (
        <div className="copyright-bar">

            <p>© 2021 <a className="hover-nav" href="https://www.stefan-lamprecht.com"> Stefan Lamprecht</a></p>
        </div>
    )

}

export default CopyrightBar