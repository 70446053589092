import React from "react";
import { motion } from "framer-motion";




const ModernPrinciples = () => {


    return (



        <section className="design-text-chapter modern-principles-container">
            <h2>modern principles</h2>
            <p>The traditional animation principles focus primarily on how the animation is perceived by the viewer. The aspect of interactivity is added to UI animations and therefore a little bit more of planing should be involved. </p>

            <ol>
                <li>have a known purpose for every animation in your interface</li>
                <li>don´t create obstacles with animation for the user</li>
                <li>keep animations flexible and don’t block the interaction</li>
                <li>focus on readability of the animation instead of the duration</li>
            </ol>

        </section>

    )

}

export default ModernPrinciples