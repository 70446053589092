import React, { createRef, useEffect } from 'react';
import lottie from 'lottie-web';
import animation from '../../../json/basics/properties/07_Depth.json';


const BasicsDepth = () => {

    let animationContainer = createRef();

    useEffect(() => {
        lottie.loadAnimation({
            container: animationContainer.current, // current instance of our container!
            animationData: animation, // animation file!
            renderer: "html",
            loop: true,
            autoplay: true
        });
    }, [])


    return (
        <div className="animation-container depth" ref={animationContainer}

        />

    )

}

export default BasicsDepth