import React from "react";
import { Switch, Route, useLocation, withRouter } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
//Google Analytics
import GoogleAnal from "react-ga";
import RouteChangeTracker from "./Tracker"
import CookieConsent from "react-cookie-consent";
//import style
import './App.scss';
import color from "./style/basic/_config.module.scss"

//import Pages
import Home from "./pages/Home";
import Basics from './pages/Basics'
import Design from './pages/Design'
import Purpose from './pages/Purpose'
import Checklist from './pages/Checklist'
import Privacy from './pages/Privacy'


const trackingId = "G-VR54VQRY7M";
GoogleAnal.initialize(trackingId);





function App() {

  const location = useLocation();



  const variants = {
    visible: {
      opacity: 1,
      transition: { duration: .5, easing: [0, .5, .5, 1] }
    },
    hidden: {
      opacity: 0,
      transition: { duration: .5, easing: [0, .5, .5, 1], delay: -10 }
    }
  }



  return (
    <div className="App">

      <RouteChangeTracker />

      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          <Route path="/" exact>
            <Home />
          </Route>

          <Route path="/basics" exact>
            <Basics />
          </Route>

          <Route path="/design" exact>
            <Design />
          </Route>

          <Route path="/purpose" exact>
            <Purpose />
          </Route>

          <Route path="/checklist" exact>
            <Checklist />
          </Route>


          <Route path="/privacy" exact>
            <Privacy />
          </Route>


        </Switch>
      </AnimatePresence>


      <motion.div
        variants={variants}
        initial="hidden"
        animate="visible"
      >
        <CookieConsent
          // debug={true}

          buttonClasses="cookie-btn"
          containerClasses="cookie-container"
          contentClasses="cookie-content-container"

          location="bottom"
          buttonText="Ok!"
          cookieName="ui-animation.net"
          expires={150}

          style={{
            background: color.blueThree,
            padding: "16px",
            jusifyContent: "center"

          }}
          buttonStyle={{
            color: color.white,
            background: color.blueFour,
            fontSize: "16px",
            borderRadius: "5px",
            padding: "8px 16px"
          }}

        >This website uses cookies to enhance the user experience. <br />
          <span className="privacy-info" style={{
            fontSize: "13px",
            color: color.greyOne,
          }}>you can learn more about privacy policies <a href="/privacy" style={{ color: color.blueTwo }}>here</a></span>
        </CookieConsent>
      </motion.div>
    </div>
  );

}

export default App;
