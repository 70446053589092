import React from "react";

const Principle = (props) => {

    return (

        <svg width="1em" height="1em" viewBox="0 0 230 319.38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <linearGradient id="a" x1="50%" x2="50%" y1="0%" y2="62.62%">
                <stop offset="0" stopColor="#d378e5" />
                <stop offset="1" stopColor="#7526c2" />
            </linearGradient>
            <path d="M0 266.28V115C0 51.49 51.49 0 115 0s115 51.49 115 115c0 62.68-50.14 113.64-112.5 114.97v.03a32.5 32.5 0 01-.5-65v-.04A50 50 0 1065 115v167.5A32.5 32.5 0 0132.5 315a32.35 32.35 0 01-18.68-5.9A22.51 22.51 0 100 266.28zM117.5 220a22.5 22.5 0 100-45 22.5 22.5 0 000 45z" fill="url(#a)" fillRule="evenodd" />
        </svg>

    );
}

export default Principle;