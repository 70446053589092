import React from "react";

import Framer from "../../../../../img/icons/components/Tools/Framer";
import InVison from "../../../../../img/icons/components/Tools/InVison";
import Principle from "../../../../../img/icons/components/Tools/Principle";




const Tools = () => {


    return (



        <section className="design-text-chapter tools-container">
            <h2>prototyping tools</h2>
            <p>Screen design tools have become an essential part of the everyday life of UI and UX designer. Accordingly, there is a large market for it. All these different tools have their pros and cons. All of them help to realize and test interfaces before they are developed. This avoids discussions about abstract ideas and uses a prepared prototype as a basis for discussion.
            </p>
            <p>
                InVison, Principle and Framer have good features for implementing animation and interactivity in digital prototypes. </p>

            <section className="icon-section">
                <a href="https://www.invisionapp.com" target="_blank">
                    <InVison width="48px" height="48px" />
                </a>

                <a href="https://principleformac.com" target="_blank">
                    <Principle width="48px" height="48px" />
                </a>

                <a href="https://www.framer.com" target="_blank">
                    <Framer width="48px" height="48px" />
                </a>
            </section>
        </section>

    )

}

export default Tools