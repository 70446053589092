import React from "react";

import "./PrincipleGrid.scss"

import { Principles } from "./PrinciplesItems";



const PrinciplesGrid = () => {

    return (
        <div className="principles-grid-container">        {
            Principles.map((principle, index) => {
                return (
                    <div key={index} className="animation-wrap">

                        <div>{principle.component}</div>

                        <p>{principle.title}</p>
                    </div>
                )
            })
        }
        </div>

    )

}


export default PrinciplesGrid;