import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion"

import ArrowIcon from "../../../img/lotties/components/icon/arrowTurn.js";
import Checkbox from "../../../img/lotties/components/icon/Checkbox.js";

import color from "../../../style/basic/_config.module.scss";


const Duration = () => {


    const [isOpen, setIsOpen] = useState(false);

    const handleInteraction = (e) => {
        setIsOpen(!isOpen)
    }


    const headlineColorAnim = {
        open: {
            color: color.white,
        },
        closed: {
            color: color.blueFour,
        }

    }


    const titleBG = {
        closed: {

            backgroundColor: color.blueFour,


            scaleY: 0,
            originY: 0,

            transition: {
                duration: .6
            }
        },
        open: {
            backgroundColor: color.blueFour,

            borderRadius: "6px 6px 0 0",
            margin: 0,
            padding: 0,


            scaleY: 1,
            originY: 0,


            transition: {
                duration: .2
            }
        }
    };



    const ChildVariants = {
        closed: {
            y: -100,
            opacity: 0,
            height: 0,

            transition: {
                delay: 0,
                duration: .3,
            },
        },
        open: {
            y: 0,
            opacity: 1,
            height: "auto",
            display: "block",

            transition: {
                delay: 0,
                duration: .3,
            }
        }
    };






    return (

        <section className="list-item" >
            <div className="title-wrap">
                <div className="checkbox"><Checkbox /></div>
                <div className="item-title">duration</div>
            </div>



            <motion.div className="info-container">

                <motion.div

                    variants={headlineColorAnim}
                    initial="closed"
                    animate={isOpen ? "open" : "closed"}

                    className={isOpen ? "subtitle-wrap background-on" : "subtitle-wrap background-off"}
                    onClick={handleInteraction}
                >

                    <motion.div className="title-BG"
                        variants={titleBG}
                        initial="closed"
                        animate={isOpen ? "open" : "closed"}
                    ></motion.div>

                    <div className="arrow">
                        <ArrowIcon width="24px" height="24px" open={isOpen} />
                    </div>

                    <div className=".item-subtitle">
                        How long will the animation take?
                        </div>

                </motion.div>

                <AnimatePresence exitBeforeEnter>
                    {isOpen && (

                        <motion.div
                            variants={ChildVariants}
                            initial="closed"
                            // animate={isOpen ? "open" : "closed"}

                            animate="open"

                            key="content"
                            exit="closed"

                            className="content-wrapper">
                            <div className=".content">
                                <p>Stay between 100ms - 750ms. Try not to create obstacles for the user. Animations that are often used in the interface should happen relatively fast.</p>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </motion.div>
        </section >

    )

}


export default Duration;