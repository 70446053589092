import React from "react";
import { motion } from "framer-motion";

import DokuDuration from "../../../../../img/lotties/components/design/01_Duration";
import DokuEasing from "../../../../../img/lotties/components/design/02_Easing";
import DokuProperties from "../../../../../img/lotties/components/design/03_Properties";
import DokuDelay from "../../../../../img/lotties/components/design/04_Delay";
import DokuIteration from "../../../../../img/lotties/components/design/05_Iteration";

import "../textContainer.scss"

const Documentation = () => {


    return (



        <section className="design-text-chapter documentation-container">
            <h2>documenting animatons</h2>
            <p>Every animation concept becomes stronger if it follows a set of rules. If you document the animation of your interface, you establish a consistent design language and create reusable components for future iterations. It is  convenient to name the documented animations based on their purpose or characteristics they achieve.
            </p>



            <h3>What aspects of an UI animation should at least be documented?</h3>
            <ol className="ol-doku">

                <li>
                    <DokuDuration />
                    <p>duration</p>
                </li>

                <li>
                    <DokuEasing />
                    <p>easing</p>
                </li>

                <li>
                    <DokuProperties />
                    <p>properties</p>
                </li>

                <li>
                    <DokuDelay />
                    <p>delay values</p>
                </li>

                <li>
                    <DokuIteration />
                    <p> iteration counts</p>
                </li>
            </ol>


            <div className="systems-wrap">
                <section className="design-systems">
                    <h4>animation Design Systems</h4>
                    <ol>
                        <li><a className="hover-card-links" href="https://material.io/design/motion/understanding-motion.html#principles" target="_blank" > <div>Google</div> <br /> Material Design</a></li>
                        <li><a className="hover-card-links" href="https://spectrum.adobe.com/page/motion/" target="_blank" > <div>Adobe</div> <br /> Spectrum</a></li>
                        <li><a className="hover-card-links" href="https://www.audi.com/ci/en/intro/basics/animation.html" target="_blank" > <div>Audi</div> <br /> CI Guidelines</a></li>
                        <li><a className="hover-card-links" href="https://www.ibm.com/design/language/animation/overview/" target="_blank" > <div>IBM</div> <br /> IBM Design Language</a></li>
                    </ol>


                </section>

            </div>

        </section>

    )

}

export default Documentation