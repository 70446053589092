import Principle01 from "../../../img/lotties/components/basics/principles/Basics01SquashStretch";
import Principle02 from "../../../img/lotties/components/basics/principles/Basics02Anticipation";
import Principle03 from "../../../img/lotties/components/basics/principles/Basics03Staging";
import Principle04 from "../../../img/lotties/components/basics/principles/Basics04FollowThroughOverlappingAction";
import Principle05 from "../../../img/lotties/components/basics/principles/Basics05StraightAheadPoseToPose";
import Principle06 from "../../../img/lotties/components/basics/principles/Basics06SlowInOut";
import Principle07 from "../../../img/lotties/components/basics/principles/Basics07Arcs";
import Principle08 from "../../../img/lotties/components/basics/principles/Basics08SecondaryAction";
import Principle09 from "../../../img/lotties/components/basics/principles/Basics09Timing";
import Principle10 from "../../../img/lotties/components/basics/principles/Basics10Exaggeration";
import Principle11 from "../../../img/lotties/components/basics/principles/Basics11SolidDrawing";
import Principle12 from "../../../img/lotties/components/basics/principles/Basics12Appeal";


export const Principles = [
    {
        title: 'Squash and Stretch',
        component: <Principle01 />
    },
    {
        title: 'Anticipation',
        component: <Principle02 />
    },
    {
        title: 'Staging',
        component: <Principle03 />
    },
    {
        title: 'Follow Through & Overlapping Action',
        component: <Principle04 />
    },
    {
        title: 'Straight Ahead & Pose to Pose',
        component: <Principle05 />
    },
    {
        title: 'Slow In & Out',
        component: <Principle06 />
    },
    {
        title: 'Arcs',
        component: <Principle07 />
    },
    {
        title: 'Seconday Action',
        component: <Principle08 />
    },
    {
        title: 'Timing',
        component: <Principle09 />
    },
    {
        title: 'Exaggeration',
        component: <Principle10 />
    },
    {
        title: 'Solid Drawing',
        component: <Principle11 />
    },
    {
        title: 'Appeal',
        component: <Principle12 />
    },

]