import React from "react";

const Logo = (props) => {

    return (

        <svg width="1em" height="1em" viewBox="0 0 764 382" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path opacity="0.4" d="M191 382C296.486 382 382 296.486 382 191C382 85.5136 296.486 0 191 0C85.5136 0 0 85.5136 0 191C0 296.486 85.5136 382 191 382Z" fill={props.color} fillOpacity="0.4" />
            <path opacity="0.4" d="M283 382C388.486 382 474 296.486 474 191C474 85.5136 388.486 0 283 0C177.514 0 92 85.5136 92 191C92 296.486 177.514 382 283 382Z" fill={props.color} fillOpacity="0.4" />
            <path opacity="0.4" d="M373 382C478.486 382 564 296.486 564 191C564 85.5136 478.486 0 373 0C267.514 0 182 85.5136 182 191C182 296.486 267.514 382 373 382Z" fill={props.color} fillOpacity="0.4" />
            <path opacity="0.4" d="M473 382C578.486 382 664 296.486 664 191C664 85.5136 578.486 0 473 0C367.514 0 282 85.5136 282 191C282 296.486 367.514 382 473 382Z" fill={props.color} fillOpacity="0.4" />
            <path opacity="0.4" d="M573 382C678.486 382 764 296.486 764 191C764 85.5136 678.486 0 573 0C467.514 0 382 85.5136 382 191C382 296.486 467.514 382 573 382Z" fill={props.color} fillOpacity="0.4" />
        </svg>


    );
}

export default Logo;