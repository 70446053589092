import React from "react";
import ScrollAnimation from "react-animate-on-scroll";


import Purpose from "./Content/00_Purpose";
import Goal from "./Content/01_Goal";
import Focus from "./Content/02_Focus";
import Duration from "./Content/03_Duration";
import Frequency from "./Content/04_Frequency";
import Trigger from "./Content/05_Trigger";
import Speed from "./Content/06_Speed";
import Accessibility from "./Content/07_Accessiblity";



import "./Checklist.scss"



const ChecklistGrid = () => {



    return (
        <div className="checklist-container">

            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <Purpose />
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <Goal />
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <Focus />
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <Duration />
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <Frequency />
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <Trigger />
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <Speed />
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <Accessibility />
            </ScrollAnimation>



        </div>

    )

}


export default ChecklistGrid;