import Accurate from "../../../img/lotties/components/purpose/personality/Accurate";
import Calm from "../../../img/lotties/components/purpose/personality/Calm";
import Energetic from "../../../img/lotties/components/purpose/personality/Energetic";
import Playful from "../../../img/lotties/components/purpose/personality/Playful";


export const Personality = [
    {
        title: 'calm',
        component: <Calm />
    },
    {
        title: 'playful',
        component: <Playful />
    },
    {
        title: 'energetic',
        component: <Energetic />
    },
    {
        title: 'accurate',
        component: <Accurate />
    },
]