import React, { useState } from 'react';
import Lottie from 'react-lottie-player';
import animation from '../../json/icon/checkbox.json';


const Checkbox = () => {

    const [play, setPlay] = useState(false);
    const [direction, setDirection] = useState(1);


    const [isClicked, setIsClicked] = useState(false);




    const handleInteraction = (e) => {

        if (!isClicked) {
            setDirection(1)
            setPlay(true)
            setIsClicked(!isClicked)

        }

        if (isClicked) {
            setDirection(-1)
            setPlay(true)
            setIsClicked(!isClicked)
        }
    }


    return (


        <Lottie
            loop={false}
            speed={2}
            play={play}
            animationData={animation}
            direction={direction}
            onComplete={() => setPlay(false)}

            onClick={handleInteraction}
        />

    )

}

export default Checkbox