import React from "react";

const InstagramIcon = (props) => {

    return (


        <svg width="1em" height="1em" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="32" cy="32" r="32" fill={props.colorBG} />
            <g clipPath="url(#clipInstagram)">
                <path fillRule="evenodd" clipRule="evenodd" d="M26.0813 18.1204H37.9192C42.3647 18.1204 46 21.7107 46 26.0967V37.9027C46 42.2898 42.3647 45.879 37.9192 45.879H26.0813C21.6358 45.879 18 42.2898 18 37.9027V26.0967C18 21.7107 21.6358 18.1204 26.0813 18.1204ZM31.9246 24.5052C36.2445 24.5052 39.7501 28.0107 39.7501 32.3307C39.7501 36.6511 36.2445 40.1561 31.9246 40.1561C27.6036 40.1561 24.0986 36.6511 24.0986 32.3307C24.0986 28.0107 27.6036 24.5052 31.9246 24.5052V24.5052ZM31.9246 27.1483C34.7853 27.1483 37.1069 29.4695 37.1069 32.3307C37.1069 35.1918 34.7852 37.5136 31.9246 37.5136C29.0629 37.5136 26.7417 35.1919 26.7417 32.3307C26.7417 29.4695 29.0629 27.1483 31.9246 27.1483ZM39.4953 23.4206C40.197 23.4206 40.766 23.9895 40.766 24.6907C40.766 25.3925 40.197 25.9614 39.4953 25.9614C38.7941 25.9614 38.2251 25.3925 38.2251 24.6907C38.2251 23.9895 38.7941 23.4206 39.4953 23.4206V23.4206ZM27.0486 20.3878H36.9529C40.6719 20.3878 43.7135 23.4144 43.7135 27.1127V37.0666C43.7135 40.7649 40.6719 43.791 36.9529 43.791H27.0486C23.3296 43.791 20.2876 40.7649 20.2876 37.0666V27.1126C20.2876 23.4144 23.3296 20.3878 27.0486 20.3878V20.3878Z" fill={props.colorIcon} />
            </g>
            <defs>
                <clipPath id="clipInstagram">
                    <rect width="28" height="28" fill="white" transform="translate(18 18)" />
                </clipPath>
            </defs>
        </svg>

    );
}

export default InstagramIcon;