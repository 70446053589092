import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie-player';
import animation from '../../json/icon/arrowTurn.json';


const ArrowTurn = (props) => {

    const [play, setPlay] = useState(false);
    const [direction, setDirection] = useState(1);
    const [isClicked, setIsClicked] = useState(props.open);

    const [firstRender, setFirstRender] = useState(true)

    useEffect(() => {

        if (firstRender) {
            //prevent anim play on first render
            setFirstRender(false)
        } else {

            if (!isClicked) {
                setDirection(1)
                setPlay(true)
                setIsClicked(!isClicked)

            }

            if (isClicked) {
                setDirection(-1)
                setPlay(true)
                setIsClicked(!isClicked)
            }
        }

    }, [props.open])



    return (


        <Lottie
            loop={false}
            speed={1}
            play={play}
            animationData={animation}
            direction={direction}

            onComplete={() => setPlay(false)}

        />

    )

}

export default ArrowTurn