import React from "react";

const TwitterIcon = (props) => {

    return (

        <svg width="1em" height="1em" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="32" cy="32" r="32" fill={props.colorBG} />
            <g clipPath="url(#clip0)">
                <path fillRule="evenodd" clipRule="evenodd" d="M45.0006 24.5566C44.1183 24.9513 43.1678 25.2135 42.1753 25.3304C43.1922 24.7214 43.9699 23.7563 44.3411 22.6074C43.3906 23.1694 42.3343 23.5818 41.215 23.8023C40.315 22.8461 39.0367 22.2517 37.6207 22.2517C34.9016 22.2517 32.7003 24.4543 32.7003 27.1716C32.7003 27.5552 32.7428 27.9303 32.8261 28.2922C28.7349 28.0849 25.1078 26.1263 22.6798 23.1464C22.2433 23.8982 22.0135 24.7521 22.0136 25.6214C22.0136 27.3275 22.8799 28.8366 24.2016 29.7166C23.3951 29.6927 22.6364 29.4677 21.9702 29.1032V29.1648C21.9702 31.5508 23.6661 33.5417 25.9197 33.9917C25.5069 34.1037 25.0711 34.1658 24.622 34.1658C24.304 34.1658 23.9974 34.133 23.6927 34.0776C24.3221 36.0318 26.1389 37.4562 28.2907 37.4978C26.6036 38.8195 24.4847 39.6066 22.1748 39.6066C21.7762 39.6066 21.3877 39.5822 20.9998 39.5357C23.1833 40.9366 25.77 41.7481 28.5489 41.7481C37.6021 41.7481 42.5571 34.2455 42.5571 27.7403C42.5571 27.5242 42.5522 27.3147 42.5434 27.103C43.5049 26.4151 44.3385 25.547 44.9984 24.5588L45.0007 24.5566L45.0006 24.5566Z" fill={props.colorIcon} />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" transform="translate(21 20)" />
                </clipPath>
            </defs>
        </svg>



    );
}

export default TwitterIcon;