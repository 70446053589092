import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Nav from "../components/Navbar/Navbar.js"
import CopyrightBar from "../components/CopyrightBar/CopyrightBar.js";


import ChecklistPageGrid from "../components/pageGrids/Page_ChecklistGrid/ChecklistPageGrid.js";
import "./transition.scss"

import color from "../style/basic/_config.module.scss"

import Logo from "../img/icons/components/Logo";



const Checklist = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.overflow = 'visible';
    }, [])


    const easing = [.74, .01, .83, .72]

    const variants = {
        initial: {
            opacity: 1,
            originY: 0,
            transition: {
                duration: 0.2,
                ease: easing
            }
        },
        animate: {
            opacity: 0,
            originY: 0,
            transition: {
                duration: 0.6,
                ease: easing,
                delay: .2
            }
        },
        exit: {
            opacity: 1,
            originY: 0,

            transition: {
                duration: 0.6,
                ease: easing
            }
        }
    }

    return (
        <div>

            <motion.div
                className="overlay"
                variants={variants}
                initial="initial"
                animate="animate"
                exit="exit"
            >
                <Logo className="logo-transition" color={color.blueTwo} width={500} height={500} />

            </motion.div>

            <Nav />
            <ChecklistPageGrid />
            <CopyrightBar />
        </div>

    )

}

export default Checklist