import React from "react";

import { TwitterShareButton, PinterestShareButton, LinkedinShareButton, FacebookShareButton, } from "react-share";

//ICONS
import FacebookIcon from "../../../../../img/icons/components/Social/FacebookIcon.js"
import LinkedInIcon from "../../../../../img/icons/components/Social/LinkedInIcon.js";
import PinterestIcon from "../../../../../img/icons/components/Social/PintererstIcon.js";
import TwitterIcon from "../../../../../img/icons/components/Social/TwitterIcon.js";



import color from "../../../../../style/basic/_config.module.scss";
import "./Share.scss"

const Share = () => {


    const shareUrl = "https://www.ui-animation.net/";

    return (
        <div className="share-container">

            <h3>Share this project</h3>
            <section className="social-icons">

                <TwitterShareButton
                    className="share-wrap"
                    url={shareUrl}
                    title="ui-animation.net | ui animation resource for designers"
                    via="UI-Animaiton.net is a resource for designers to consider when they strive to create meaningful and usable UI animations."
                    hashtags={["#ui-animation.net"]}>
                    <TwitterIcon className="icon hover-icon" colorBG={color.blueThree} colorIcon={color.white} width={64} height={64} />
                </TwitterShareButton>

                <PinterestShareButton
                    className="share-wrap"
                    url={shareUrl}
                    media="../../../../../img/icons/svg/Logo.svg"
                    description="UI-Animaiton.net is a resource for designers to consider when they strive to create meaningful and usable UI animations.">
                    <PinterestIcon className="icon hover-icon" colorBG={color.blueThree} colorIcon={color.white} width={64} height={64} />
                </PinterestShareButton>

                <LinkedinShareButton
                    className="share-wrap"
                    url={shareUrl}
                    title="ui-animation.net | ui animation resource for designers"
                    summary="UI-Animaiton.net is a resource for designers to consider when they strive to create meaningful and usable UI animations."
                    source="ui-animation.net">
                    <LinkedInIcon className="icon hover-icon" colorBG={color.blueThree} colorIcon={color.white} width={64} height={64} />
                </LinkedinShareButton>

                <FacebookShareButton
                    className="share-wrap"
                    url={shareUrl}
                    quote="check out ui-animation.net"
                    hastag="#uianimaiton">
                    <FacebookIcon className="icon hover-icon" colorBG={color.blueThree} colorIcon={color.white} width={64} height={64} />
                </FacebookShareButton>
            </section>

        </div >

    );
}

export default Share;