import Easing01 from "../../../img/lotties/components/basics/easing/Basics01Linear"
import Easing02 from "../../../img/lotties/components/basics/easing/Basics02EaseInOut"
import Easing03 from "../../../img/lotties/components/basics/easing/Basics03EaseIn"
import Easing04 from "../../../img/lotties/components/basics/easing/Basics04EaseOut"



export const Easings = [
    {
        title: 'Linear',
        component: <Easing01 />
    },
    {
        title: 'Ease-In-Out',
        component: <Easing02 />
    },
    {
        title: 'Ease In',
        component: <Easing03 />
    },
    {
        title: 'Ease Out',
        component: <Easing04 />
    }

]