export const MenuItems = [
    {
        title: 'basic',
        url: '/basics',
        className: 'nav-link'
    },
    {
        title: 'design',
        url: '/design',
        className: 'nav-link'
    },
    {
        title: 'purpose',
        url: '/purpose',
        className: 'nav-link'
    },
    {
        title: 'checklist',
        url: '/checklist',
        className: 'nav-link'
    }
]