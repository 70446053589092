import React from "react";

import "./PurposePageGrid.scss";


import NextPage from "../../NextPage/NextPage.js";


import HeaderPurpose from "../../Header/HeaderPurpose/HeaderPurpose.js"

//TEXT
import VisualGuidance from "./textChapters/VisualGuidance/VisualGuidance.js";
import Personality from "./textChapters/Personality/Personality.js";
import Attention from "./textChapters/Attention/Attention.js";




const PurposePageGrid = () => {


    return (
        <div className="purposePage-grid-container">

            <HeaderPurpose />

            <VisualGuidance />
            <Attention />
            <Personality />

            <NextPage
                className="next-page-container"
                leftPath="/design"
                leftName="design"
                rightPath="/checklist"
                rightName="checklist"
                show={true}
            />

        </div>
    )

}

export default PurposePageGrid