import React from 'react'
import { withRouter } from 'react-router-dom';
import GoogleAnal from 'react-ga';

const RouteChangeTracker = ({ history }) => {

    history.listen((location, action) => {
        GoogleAnal.set({ page: location.pathname });
        GoogleAnal.pageview(location.pathname);
    });

    return <div></div>;
};

export default withRouter(RouteChangeTracker);
