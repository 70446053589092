import React from "react";

const MailIcon = (props) => {

    return (

        <svg width="1em" height="1em" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="32" cy="32" r="32" fill={props.colorBG} />
            <g clipPath="url(#clipMail)">
                <rect x="19" y="23" width="26" height="18.2222" rx="1" stroke={props.colorIcon} strokeWidth="2" />
                <path d="M19.5554 23.5554L31.9999 34.4443L44.4443 23.5554" stroke={props.colorIcon} strokeWidth="2" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clipMail">
                    <rect width="28" height="20.248" fill="white" transform="translate(18 22)" />
                </clipPath>
            </defs>
        </svg>


    );
}

export default MailIcon;