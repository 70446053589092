import React from "react";

const PinterestIcon = (props) => {

    return (


        <svg width="1em" height="1em" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="32" cy="32" r="32" fill={props.colorBG} />
            <g clipPath="url(#clipPinterest)">
                <path fillRule="evenodd" clipRule="evenodd" d="M25.3189 47.7779C27.0273 46.2543 28.2 44.0842 28.7726 41.8587C29.0034 40.9815 29.9453 37.3893 29.9453 37.3893C30.5548 38.5621 32.3555 39.5502 34.2485 39.5502C39.9184 39.5502 44 34.342 44 27.8503C44 21.6356 38.9303 16.9907 32.4109 16.9907C24.2939 16.9907 19.9907 22.439 19.9907 28.3674C19.9907 31.1192 21.459 34.5544 23.8045 35.6441C24.1554 35.8195 24.3493 35.7364 24.4324 35.3855C24.4878 35.1177 24.811 33.8526 24.9588 33.2524C25.005 33.0677 24.9865 32.8922 24.8295 32.7075C24.0538 31.7656 23.4259 30.0296 23.4259 28.4228C23.4259 24.2766 26.5656 20.2597 31.9122 20.2597C36.5294 20.2597 39.7614 23.3993 39.7614 27.9057C39.7614 32.9938 37.1943 36.5213 33.8514 36.5213C32.0046 36.5213 30.6194 34.9976 31.0719 33.1231C31.6075 30.8884 32.6325 28.4782 32.6325 26.8714C32.6325 25.4309 31.8661 24.2304 30.2501 24.2304C28.357 24.2304 26.8518 26.1789 26.8518 28.8014C26.8518 30.4636 27.4059 31.5902 27.4059 31.5902C27.4059 31.5902 25.5405 39.4671 25.1989 40.9353C24.8203 42.5606 24.968 44.8414 25.1342 46.3282L25.3005 47.7872L25.3189 47.7779Z" fill={props.colorIcon} />
            </g>
            <defs>
                <clipPath id="clipPinterest">
                    <rect width="24" height="30.778" fill="white" transform="translate(20 17)" />
                </clipPath>
            </defs>
        </svg>



    );
}

export default PinterestIcon;