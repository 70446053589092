import React from "react";

const DesignIcon = (props) => {

    return (


        <svg width="1em" height="1em" viewBox="0 0 192 192" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="96" cy="96" r="96" fill={props.colorBG} />
            <rect x="95.6396" y="32" width="90" height="90" transform="rotate(45 95.6396 32)" stroke={props.colorIcon} strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" className="icon-stroke" />
        </svg>



    );
}

export default DesignIcon;