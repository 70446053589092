import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
import animation from '../../../json/icon/Logo/logo_grow.json';


const LogoGrow = () => {

    const [play, setPlay] = useState(false);
    const [direction, setDirection] = useState(1);


    useEffect(() => {
        const timer = setTimeout(() => {
            setPlay(true)
        }, 500);
        return () => clearTimeout(timer);
    }, []);



    return (

        <Lottie
            loop={false}
            speed={1}
            play={play}
            animationData={animation}
            direction={direction}

            className="animation-container"
        />


    )

}

export default LogoGrow