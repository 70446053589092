import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

import "../textChapters.scss"

//ANIM
import ConeOfVison from "../../../../../img/lotties/components/purpose/ConeOfVison"
import UserEducation from "../../../../../img/lotties/components/purpose/UserEducation"
import ErrorHandling from "../../../../../img/lotties/components/purpose/ErrorHandling"


const Attention = () => {


    return (



        <section className="text-chapter attention-container">

            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <div className="chapter-header">
                    <h2>Attention</h2>
                    <p>Animation is incredibly useful for attracting attention. Combining animation with the most important elements of an interface ensures that users will definitely notice the elements.</p>
                </div>
            </ScrollAnimation>

            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <section>
                    <div className="container">
                        <div className="txt-container">
                            <h3>NOTIFICATIONS</h3>
                            <p>Notifications indicate important information concerning the interaction with the UI. They should only appear when they are relevant, otherwise they may annoy the user.</p>
                            <p>Human are sensitive to color and detail in a very small region of their field of view. The outer edge of the view is colourless and blur, but it is very sensitive to motion. These properties of the visual sense strongly influence how humans perceive UI animations.</p>
                        </div>
                        <div className="cone-of-vision-lottie">
                            <ConeOfVison className="lottie" width={300} height={300} />
                        </div>
                    </div>
                </section>
            </ScrollAnimation>

            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <section>
                    <div className="container reverse">
                        <div className="txt-container">
                            <h3>USER EDUCATION</h3>
                            <p>If an interface feels like a natural conversation, it is most likely well designed. Layout, shapes, icons, colours and animations can influence how the function of UI elements are perceived. The term affordance describes the relationship between an UI element and a user.</p>
                            <p>When animation is used to show the effect of an interaction it makes it easier for the user to understand the UI. By showing the causality with animations, the functionality of UI elements can be shown and affordance can be strengthened.</p>
                        </div>
                        <div className="user-education-lottie">
                            <UserEducation className="lottie" width={100} height={100} />
                        </div>

                    </div>
                </section>
            </ScrollAnimation>

            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <section>
                    <div className="container">
                        <div className="txt-container">
                            <h3>ERROR HANDLING</h3>
                            <p>When designing UI, designers must pay attention to the situations that do not work as planned. If the user is able to understand the conflict and take the right actions, the interaction between user and UI is well designed.</p>
                            <p>When error messages are easily missed, the interaction around the error can be annoying. Animation can assist to make the error messages easily recognisable. This way designers can avoid showing new messages and instead just move certain UI elements to communicate the error.</p>
                        </div>
                        <div className="error-handling-lottie">                <ErrorHandling className="lottie" width={300} height={300} />
                        </div>
                    </div>
                </section>
            </ScrollAnimation>

        </section>

    )

}

export default Attention