import React from "react";
import ScrollAnimation from "react-animate-on-scroll";


import "../textChapters.scss"

import PurposeFeedback from "../../../../../img/lotties/components/purpose/PurposeFeedback";
import Loader from "../../../../../img/lotties/components/purpose/Loader";
import ContextSwitch_anim from "../../../../../img/lotties/components/purpose/ContextSwitch_anim";
import ContextSwitch_cut from "../../../../../img/lotties/components/purpose/ContextSwitch_cut";



const VisualGuidance = () => {


    return (

        <section className="text-chapter visual-guidance-container">
            <ScrollAnimation animateIn='fadeIn' offset={0} >

                <div className="chapter-header">
                    <h2>visual guidance</h2>
                    <p>Users need clues about how to interact with an interface and the different ways they can do so. Good UI animations require clear communication of the intention and activity of the system to the user.</p>
                </div>
            </ScrollAnimation>

            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <section>
                    <div className="container reverse">
                        <div className="txt-contanier">
                            <h3>feedback</h3>
                            <p>The systems feedback is the result of an interaction. If this feedback is not provided within 400ms, users may be distracted or move on to another activity. If the communicated information is irrelevant or happens too often, it can lead to all feedback being ignored by the user.</p>
                            <p>It happens that static feedback is not perceived by the user because he or she ignores subconsciously small changes in the interface. Since animation can easily attract attention, it is particularly useful for providing feedback.</p>
                        </div>

                        <div className="feedback-lottie" >
                            <PurposeFeedback className="lottie" width={300} height={300} /></div>
                    </div>
                </section>
            </ScrollAnimation>

            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <section>
                    <h3>HIERARCHY AND RELATIONSHIPS</h3>


                    <p>Dividing the UI into clearly defined areas enables the user to find the way around more quickly. It helps to focus the UI elements that are important for the current action. The relationship between UI elements should represent which UI elements are the most important. A good visual hierarchy saves users the effort of scanning the interface with great cognitive load.</p>
                    <p>Animation can guide the user’s eye through the hierarchy of the UI. Designers should consider where the user is currently looking. This way they can create animations that are logical and guide the visual flow of the user. This way the user can follow the flow of information effortlessly.</p>
                </section>
            </ScrollAnimation>

            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <section>
                    <h3>CONTEXT SWITCHES</h3>

                    <p>The understanding of what function an UI element has can be seen as the mental model of an UI. The perceived structure of the interface provides clues to predict how elements will behave after interaction.</p>
                    <p>When the context of the UI changes without animation it is difficult to communicate relationship between UI elements. These cuts in the interaction always have the effect of resetting the mental model of the UI. When a new page layout of the UI is displayed, animation can connect the context of the different phases and therefore reduce the cognitive load of the user.</p>

                    <div className="context-lottie">
                        <ContextSwitch_anim className="lottie" width={300} height={300} />
                        <ContextSwitch_cut className="lottie" width={300} height={300} />
                    </div>
                </section>
            </ScrollAnimation>

            <ScrollAnimation animateIn='fadeIn' offset={0} >
                <section>
                    <h3>SYSTEM STATUS AND PROGRESS</h3>
                    <p>Long waiting times and lack of feedback from the system can quickly lead to a bad user experience. The user should always know what state the interface is in and how long it will take to continue their task.</p>
                    <p>If loading animations are customised and informative, the user is more likely to wait for the UI. Since time is a big aspect of animation, it can be used perfectly in situations where time must pass. This way designers can use the available time and provide information and entertainment.</p>

                    <div className="loader-lottie">
                        <Loader className="lottie" width={300} height={300} />
                    </div>
                </section>
            </ScrollAnimation>
        </section>

    )

}

export default VisualGuidance