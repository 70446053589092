import React from "react";

const ChecklistIcon = (props) => {

    return (

        <svg width="1em" height="1em" viewBox="0 0 192 192" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="96" cy="96" r="96" fill={props.colorBG} />
            <path d="M73 131L119 85L142 62" stroke={props.colorIcon} strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" className="icon-stroke" />
            <path d="M73 131L50 108" stroke={props.colorIcon} strokeWidth="15" strokeLinecap="round" strokeLinejoin="round" className="icon-stroke" />
        </svg>


    );
}

export default ChecklistIcon;