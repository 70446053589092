import React, { createRef, useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
import animation from '../../../json/icon/Logo/Logo_anim_nav.json';


const LogoAnimNav = ({ menuOpen }) => {




    const [segmentFrom, setSegmentFrom] = useState(0);
    const [segmentTo, setSegmentTo] = useState(18);
    const [segmentsEnabled, setSegmentsEnabled] = useState(true);
    const segments = [segmentFrom, segmentTo];


    const [play, setPlay] = useState(false);
    const [direction, setDirection] = useState(1);
    const [isClicked, setIsClicked] = useState(menuOpen);


    const handleInteraction = (e) => {

        if (!isClicked) {
            setDirection(1)
            setPlay(true)
            setIsClicked(!isClicked)

        }

        if (isClicked) {
            setDirection(-1)
            setPlay(true)
            setIsClicked(!isClicked)
        }
    }

    return (

        <Lottie
            loop={false}
            speed={1}
            play={menuOpen}
            animationData={animation}
            direction={direction}

            segments={segmentsEnabled && segments}

            className="animation-container"
        />


    )

}

export default LogoAnimNav