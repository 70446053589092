import React, { useState, useEffect } from "react";

import { motion } from "framer-motion";
import "../Header.scss"


import Logo from "../../../img/icons/components/Logo";
import LogoGrow from "../../../img/lotties/components/icon/Logo/Logo_anim_grow"

import Icon from "../../../img/icons/components/DesignIcon";

import color from "../../../style/basic/_config.module.scss";

import ArrowScroll from "../../../img/lotties/components/icon/arrowScroll"


const HeaderDesign = () => {

    function getWindowDimensions() {
        const width = window.innerWidth;
        return width;
    }


    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {

        function getWindowDimensions() {
            const width = window.innerWidth;
            return width;
        }

        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const [iconTop, setIconTop] = useState(300)


    useEffect(() => {
        if (windowDimensions < 1025) {
            setIconTop(150)
        } else {
            setIconTop(300)
        }

    }, [windowDimensions])




    const easing = [.74, .01, .83, .72];
    const easeOut = [.03, .13, .14, .92];

    const header = {
        initial: {
            scale: 1,
            opacity: .5,

            transition: {
                duration: .2,
                ease: easing,
            }
        },
        animate: {
            scale: 1,
            opacity: 1,

            transition: {
                duration: .2,
                ease: easeOut,
            }
        },
        exit: {
            scale: 1.05,
            opacity: .5,

            transition: {
                duration: .5,
                ease: easing,
            }
        }
    }

    const icon = {
        initial: {
            scale: 1.3,
            opacity: 0,
            y: iconTop,
            transition: {
                duration: .6,
                ease: easing,
            }
        },
        animate: {
            scale: 1,
            opacity: 1,
            y: iconTop,
            transition: {
                duration: .7,
                ease: easeOut,
                delay: .7
            }
        },
        exit: {
            scale: 1.3,
            opacity: 0,
            transition: {
                duration: .6,
                ease: easing
            }
        }
    }

    const headline = {
        initial: {
            scale: 1.1,
            opacity: 0,
            y: -50,
            transition: {
                duration: .6,
                ease: easing,
            }
        },
        animate: {
            scale: 1,
            opacity: 1,
            x: 0,
            y: 0,
            transition: {
                duration: .7,
                ease: easeOut,
                delay: 1.1
            }
        },
        exit: {
            scale: 1.1,
            opacity: 0,
            transition: {
                duration: .6,
                ease: easing,
            }
        }
    }



    return (
        <motion.header
            variants={header}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            <motion.div
                className="icon-wrap"

                variants={icon}
                initial="initial"
                animate="animate"
                exit="exit"
            >
                <Icon className="icon" colorBG={color.blueThree} colorIcon={color.white} />
            </motion.div>
            <div className="headline-wrap">
                <motion.h1
                    variants={headline}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                >design</motion.h1>
            </div>
            <ArrowScroll className="arrow-scroll" />

            <div className="logo-wrap">
                <LogoGrow className="logo-anim" />
            </div>
        </motion.header>

    )

}

export default HeaderDesign