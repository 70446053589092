import React from "react";

const ArrowIconLeft = (props) => {

    return (

        <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M22.6066 31.7179L9 18.1113" stroke={props.color} strokeWidth="5.13137" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22.6066 4L9 17.6066" stroke={props.color} strokeWidth="5.13137" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
}

export default ArrowIconLeft;