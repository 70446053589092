import React from "react";

const InVison = (props) => {

    return (

        <svg width="1em" height="1em" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} preserveAspectRatio="xMinYMin meet">
            <path d="M232.8 0H23.2A23.2 23.2 0 000 23.2v209.6A23.2 23.2 0 0023.2 256h209.6a23.2 23.2 0 0023.2-23.2V23.2A23.2 23.2 0 00232.8 0" fill="#DC395F" />
            <path d="M85.56 80.55c8.5 0 15.63-6.67 15.63-15.42 0-8.73-7.12-15.4-15.63-15.4s-15.64 6.67-15.64 15.4c0 8.74 7.13 15.42 15.64 15.42m-32.43 82.52a52.93 52.93 0 00-1.38 11.57c0 13.57 7.36 22.58 23 22.58 12.97 0 23.48-7.7 31.05-20.14l-4.62 18.55h25.75l14.72-59.03c3.68-14.95 10.81-22.71 21.62-22.71 8.51 0 13.8 5.3 13.8 14.03 0 2.53-.23 5.29-1.15 8.28l-7.59 27.13a40.79 40.79 0 00-1.6 11.5c0 12.89 7.58 22.3 23.45 22.3 13.57 0 24.38-8.73 30.36-29.66l-10.12-3.9c-5.06 14.02-9.43 16.55-12.88 16.55-3.45 0-5.3-2.3-5.3-6.9 0-2.06.47-4.36 1.16-7.12l7.36-26.44a58.3 58.3 0 002.53-16.77c0-19.78-11.96-30.1-26.45-30.1-13.57 0-27.37 12.24-34.26 25.12l5.05-23.12h-39.32l-5.52 20.36h18.4l-11.33 45.36c-8.9 19.78-25.24 20.1-27.3 19.64-3.36-.76-5.51-2.04-5.51-6.41 0-2.53.46-6.16 1.6-10.53L95.92 94.8H52.2l-5.52 20.36h18.17l-11.73 47.91" fill="#FFF" />
        </svg>

    );
}

export default InVison;