import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion"


import MenuIcon from "../../img/lotties/components/icon/MenuIcon";
import Logo from "../../img/lotties/components/icon/Logo/Logo_anim_nav.js"

import "./Navbar.scss";
import { MenuItems } from './MenuItems';
import { Link, NavLink, useLocation } from "react-router-dom";

const Navbar = () => {



    function getWindowDimensions() {
        const width = window.innerWidth;
        return width;
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {

        function getWindowDimensions() {
            const width = window.innerWidth;
            return width;
        }

        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const menuInteraction = (e) => {


        if (windowDimensions < 600) {

            if (menuIsOpen) {
                setMenuIsOpen(false)
                document.body.style.overflow = 'visible';
            }
            if (!menuIsOpen) {
                setMenuIsOpen(true)
                document.body.style.overflow = 'hidden';
            }

        }
    }


    const menuBG = {
        initial: {
            opacity: 0,
            transition: {
                duration: .3
            }
        },
        animate: {
            opacity: 1,
            transition: {
                duration: .3
            }
        },
        exit: {
            opacity: 0,
            transition: {
                duration: .3
            }
        }
    }


    const NavPoints = {
        closed: {
            y: 500,
            opacity: 1,

            transition: {
                delay: 0,
                duration: .3,
            },
        },
        open: {
            y: 0,

            opacity: 1,
            display: "block",

            transition: {
                delay: 0,
                duration: .3,
                staggerChildren: 1
            }
        }
    };





    return (
        <nav className="navbar">

            <a className="menu-btn"
                onClick={menuInteraction}
            >
                <MenuIcon menuOpen={menuIsOpen} />
            </a>


            <AnimatePresence exitBeforeEnter>
                {menuIsOpen && (
                    <motion.div className="menu-bg"
                        variants={menuBG}
                        key="BG"
                        initial="initial"
                        animate="animate"
                        exit="exit"
                    ></motion.div>
                )}
            </AnimatePresence>

            <AnimatePresence exitBeforeEnter>
                //!======MOBILE NAV==============
                {menuIsOpen && (
                    <motion.div
                        className={menuIsOpen ? 'menu-container-open' : 'menu-container-closed'}

                        variants={NavPoints}
                        initial="closed"

                        animate="open"

                        key="content"
                        exit="closed"
                    >

                        <Logo menuOpen={menuIsOpen} />

                        <ol>
                            {MenuItems.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <NavLink className={item.className + ` hover-nav`} to={item.url}
                                            //onClick={menuInteraction}

                                            activeClassName="active"
                                        >
                                            {item.title}
                                        </NavLink>
                                    </li>
                                )
                            })}
                        </ol>
                    </motion.div>
                )}

                //!======DESKTOP NAV==============

                {windowDimensions > 600 && (
                    <motion.div
                        className={menuIsOpen ? 'menu-container-open' : 'menu-container-closed'}

                        variants={NavPoints}
                        key="nav"
                        initial="closed"

                        animate="open"

                        exit="closed"

                    >

                        <Logo menuOpen={menuIsOpen} />

                        <ol>
                            {MenuItems.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <Link className={item.className} to={item.url}
                                            onClick={menuInteraction}
                                        >
                                            <p className="hover-nav"> {item.title} </p>
                                        </Link>
                                    </li>
                                )
                            })}
                        </ol>
                    </motion.div>
                )}
            </AnimatePresence>

            <Link to="/" className="info hover-nav"> <p>Info</p></Link>


        </nav>

    );
}

export default Navbar;