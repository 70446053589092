import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom"

import DesignIcon from "../../../img/icons/components/DesignIcon";
import ArrowIcon from "../../../img/icons/components/ArrowIcon";

import color from "../../../style/basic/_config.module.scss";
import "../CardsStyle.scss"

const CardDesign = () => {

    const easing = [.74, .01, .83, .72]

    const variants = {
        initial: {
            scale: 1.3,
            opacity: 0,
            transition: {
                duration: .6,
                ease: easing,
            }
        },
        animate: {
            scale: 1,
            opacity: 1,
            transition: {
                duration: .6,
                ease: easing,
                delay: .2
            }
        },
        exit: {
            scale: 1.3,
            opacity: .9,
            transition: {
                duration: .6,
                ease: easing
            }
        }
    }


    return (


        <motion.div
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
        >

            <div className="card-container">

                <Link to="/design">
                    <DesignIcon className="icon hover-link" colorBG={color.blueThree} colorIcon={color.blueTwo} />
                </Link>
                <section className="content-wrap">

                    <h2 className="headline">design</h2>
                    <p className="description">Some useful techniques to create strong consistent motion concepts and build long term solutions.</p>

                    <section className="read-more-wrap">
                        <Link className="hover-card" to="/design">read more <ArrowIcon className="arrow" color={color.greyThree} /></Link></section>
                </section>

            </div>
        </motion.div>
    );
}

export default CardDesign;