import React from "react";
import { Link } from "react-router-dom"

import color from "../../style/basic/_config.module.scss";

import ArrowIcon from "../../img/icons/components/ArrowIcon.js";
import ArrowIconLeft from "../../img/icons/components/ArrowIconLeft.js";

import "./NextPage.scss"


const NextPage = (props) => {


    return (
        <div className="next-page-container">

            <Link className="hover-previous" to={props.leftPath}>   <ArrowIconLeft color={color.blueFour} className="icon" />{props.leftName}</Link>
            <Link className="hover-next" to={props.rightPath}>{props.rightName}<ArrowIcon color={color.blueFour} className={props.show ? "icon show" : "icon hide"} /></Link>
        </div>
    )

}

export default NextPage