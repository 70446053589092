import React from "react";

import "./TypesGrid.scss"

import { Types } from "./TypesItems";



const TypesGrid = () => {

    return (
        <div className="types-grid-container">        {
            Types.map((type, index) => {
                return (
                    <div key={index} className="animation-wrap">

                        <div>{type.component}</div>

                        <p>{type.title}</p>
                    </div>
                )
            })
        }
        </div>

    )

}


export default TypesGrid;