import React from "react";

const Framer = (props) => {

    return (

        <svg width="1em" height="1em" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipRule="evenodd" fillRule="evenodd">
                <path d="M32 21.3h21.7V0h-43v.2z" fill="#67dbff" />
                <path d="M32 21.3H10.3v21.3h43v-.2z" fill="#01a3ff" />
                <path d="M10.3 42.5h21.5V64z" fill="#0162ff" />
            </g>
        </svg>

    );
}

export default Framer;