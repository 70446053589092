import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

import "./DesignPageGrid.scss"

import ModernPrinciples from "./textContent/modernPrinciples/ModernPrinciples";
import Documentation from "./textContent/documentation/Documentation";
import Tools from "./textContent/tools/Tools";

import NextPage from "../../NextPage/NextPage.js";

import HeaderDesign from "../../Header/HeaderDesign/HeaderDesign.js"



const DesignPageGrid = () => {


    return (
        <div className="design-grid-container">

            <HeaderDesign />

            <ScrollAnimation animateIn='fadeIn' delay={100}>
                <Tools />
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' delay={100}>
                <Documentation />
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' delay={100}>
                <ModernPrinciples />
            </ScrollAnimation>

            <NextPage
                leftPath="/basics"
                leftName="basics"
                rightPath="/purpose"
                rightName="purpose"
                show={true}
            />

        </div>
    )

}

export default DesignPageGrid