import Type01 from "../../../img/lotties/components/basics/types/Basics01Attention";
import Type02 from "../../../img/lotties/components/basics/types/Basics02EntranceExit";
import Type03 from "../../../img/lotties/components/basics/types/Basics03Feedback";
import Type04 from "../../../img/lotties/components/basics/types/Basics04Personality";
import Type05 from "../../../img/lotties/components/basics/types/Basics05Relationship";
import Type06 from "../../../img/lotties/components/basics/types/Basics06Transition";


export const Types = [
    {
        title: 'Attention',
        component: <Type01 />
    },
    {
        title: 'Entrance & Exit',
        component: <Type02 />
    },
    {
        title: 'Feedback',
        component: <Type03 />
    },
    {
        title: 'Personality',
        component: <Type04 />
    },
    {
        title: 'Relationship',
        component: <Type05 />
    },
    {
        title: 'Transition',
        component: <Type06 />
    },


]