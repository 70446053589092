import React, { createRef, useEffect } from 'react';
import lottie from 'lottie-web';
import animation from '../../../json/basics/principles/12_Appeal.json';


const BasicsAppeal = () => {

    let animationContainer = createRef();

    useEffect(() => {
        lottie.loadAnimation({
            container: animationContainer.current, // current instance of our container!
            animationData: animation, // animation file!
            renderer: "svg",
            loop: true,
            autoplay: true
        });
    }, [])



    return (
        <div className="animation-container" ref={animationContainer} />

    )

}

export default BasicsAppeal